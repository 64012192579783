<template>
  <div :class="generalClass">
    <div
      class="row"
      :class="$store.getters['layout/getInnerWidth'] < 900 ? 'p-2' : ''"
    >
      <div :class="getTitleClass">
        <h6 class="mt-4 title-perso-infs">
          {{ $t('views.client.details.infos.personal-informations.title') }}
        </h6>
      </div>
      <div :class="getBorderClass" class="border-bottom-title">
      </div>
      <div class="container-fluid">
        <b-row :class="'mt-2 '+getMargins">
          <b-col class="left-general-infs" :cols="getTelephoneCols">
            {{ $t('views.client.details.infos.personal-informations.phoneNumber') }}
          </b-col>
          <b-col class="right-general-infs text-right">
            {{
              client.phoneNumber && client.phoneNumber.trim().length ? client.phoneNumber : $t('general.actions.not-specified')
            }}
          </b-col>
        </b-row>
        <b-row :class="'mt-2 '+getMargins">
          <b-col class="left-general-infs" :cols="getEmailCols">
            {{ $t('views.client.details.infos.personal-informations.email') }}
          </b-col>
          <b-col class="email-trancuate right-general-infs text-right">
            {{ client.email && client.email.trim().length ? client.email : $t('general.actions.not-specified') }}
          </b-col>
        </b-row>
        <b-row v-if="age !== $t('general.actions.undefined')" :class="'mt-2 '+getMargins">
          <b-col class="left-general-infs" :cols="getCols">
            {{ $t('views.client.details.infos.personal-informations.age') }}
          </b-col>
          <b-col class="right-general-infs text-right"> {{ age }}</b-col>
        </b-row>
        <b-row :class="'mt-2 '+getMargins">
          <b-col class="left-general-infs" :cols="getCols">
            {{ $t('views.client.details.infos.personal-informations.gender.label') }}
          </b-col>
          <b-col class="right-general-infs text-right">
            {{ $t('views.client.details.infos.personal-informations.gender.' + client.gender) }}
          </b-col>
        </b-row>
        <b-row
          v-if="customerAddresses !== $t('general.actions.not-specified') && customerAddresses.length > 0"
          :class="'mt-2 '+getMargins"
        >
          <b-col class="left-general-infs" :cols="getCols">
            {{ $t('views.client.details.infos.personal-informations.address') }}
          </b-col>
          <b-col class="right-general-infs text-right"> {{ customerAddresses }}</b-col>
        </b-row>
        <b-row v-if="isCustomerCity" :class="'mt-2 '+getMargins">
          <b-col class="left-general-infs" :cols="getCols">
            {{ $t('views.client.details.infos.personal-informations.city') }}
          </b-col>
          <b-col class="right-general-infs text-right"> {{ customerCity }}</b-col>
        </b-row>
        <b-row v-if="isCustomerCodeZip" :class="'mt-2 '+getMargins">
          <b-col class="left-general-infs" :cols="getCols">
            {{ $t('views.client.details.infos.personal-informations.zipCode') }}
          </b-col>
          <b-col class="right-general-infs text-right"> {{ customerZipCode }}</b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    client: {
      type: Object,
      default: () => {
      },
    },
    generalClass: {
      type: String,
      default: '',
    }
  },
  data: () => ({
    innerWidth: 1600,
  }),
  computed: {
    isCustomerCity() {
      return this.customerCity && this.customerCity.length;
    },
    isCustomerCodeZip() {
      return this.customerZipCode && this.customerZipCode.length;
    },
    customerAddresses() {
      if (this.client.addresses.length) {
        if (this.client.addresses[0].address.length) {
          let allAddresses = '';
          for (const i in this.client.addresses[0].address) {
            allAddresses += parseInt(i) === 0 ? this.client.addresses[0].address[i] : ' ' + this.client.addresses[0].address[i];
          }
          return allAddresses.trim();
        } else {
          return this.$t('general.actions.not-specified');
        }
      } else {
        return this.$t('general.actions.not-specified');
      }
    },
    customerZipCode() {
      if (this.client.addresses.length) {
        return this.client.addresses[0].zipCode;
      } else {
        return this.$t('general.actions.not-specified');
      }
    },
    customerCity() {
      if (this.client.addresses.length) {
        return this.client.addresses[0].city;
      } else {
        return this.$t('general.actions.not-specified');
      }
    },
    age() {
      return this.client.birthDate ? this.$moment.utc().diff(this.$moment.utc(this.client.birthDate), 'years') : this.$t('general.actions.undefined');
    },
    getMargins() {
      if (this.innerWidth < 1200) {
        return '';
      } else {
        return 'ml-3 mr-2';
      }
    },
    getCols() {
      if (this.innerWidth < 900) {
        return '';
      } else {
        return '6';
      }
    },
    getTelephoneCols() {
      if (this.innerWidth < 900) {
        return '';
      } else {
        return '5';
      }
    },
    getEmailCols() {
      return '4';
    },
    getTitleClass() {
      if (this.innerWidth > 1500 && this.innerWidth < 1850) {
        return 'col-md-10'
      } else if (this.innerWidth > 1485 && this.innerWidth < 1500) {
        return 'col-md-9'
      } else if (this.innerWidth > 1200 && this.innerWidth < 1485) {
        return 'col-md-10'
      } else if (this.innerWidth > 700 && this.innerWidth < 1200) {
        return 'col-md-12'
      } else {
        return 'col-md-8'
      }


    },
    getBorderClass() {
      if (this.innerWidth > 1500 && this.innerWidth < 1850) {
        return 'col-md-2'
      } else if (this.innerWidth > 1485 && this.innerWidth < 1500) {
        return 'col-md-3'
      } else if (this.innerWidth > 1200 && this.innerWidth < 1485) {
        return 'col-md-2'
      } else if (this.innerWidth > 700 && this.innerWidth < 1200) {
        return 'col-md-12'
      } else {
        return 'col-md-4'
      }

    }
  },
  methods: {
    handleResize() {
      this.innerWidth = window.innerWidth;
    },
  },
  created() {
    this.innerWidth = window.innerWidth;
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  }
}
</script>
<style scoped>
.title-perso-infs {
  font: normal normal bold 21px/13px Avenir;
  letter-spacing: 0px;
  color: #4D4F5C;
  opacity: 1;
}


@media only screen and (max-width: 791px) {
  .title-perso-infs {
    font: normal normal bold 10px Avenir;
    letter-spacing: 0px;
    color: #4D4F5C;
    opacity: 1;
  }
}

.email-trancuate {
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media only screen and (max-width: 991px) {
  .title-perso-infs {
    font: normal normal bold 14px Avenir;
    letter-spacing: 0px;
    color: #4D4F5C;
    opacity: 1;
  }
}

@media only screen and (max-width: 1527px) {
  .title-perso-infs {
    font: normal normal bold 18px Avenir;
    letter-spacing: 0px;
    color: #4D4F5C;
    opacity: 1;
  }
}

.border-bottom-title {
  border-bottom: 2px solid #EDF0F3;
}

.right-general-infs {
  text-align: left;
  font: normal normal 15px Avenir;
  letter-spacing: 0px;
  color: #4d4f5c;
  opacity: 0.5;
}

.left-general-infs {
  text-align: left;
  font: normal normal 15px Avenir;
  letter-spacing: 0px;
  color: #4D4F5C;
  opacity: 1;
}
</style>
